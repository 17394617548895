<script setup>
import { useRouter } from "vue-router";
import { useMenuStore } from "../../stores/menus";

import PageHeader from "../../components/layout/PageHeader.vue";
import RightSideBar from "../../views/index/components/right-bar.vue";

import Menus from "@/utils/menus";

console.log("Menus --->", Menus);

const router = useRouter();
const menuStore = useMenuStore();

const menus = [...Menus];

const toUrl = (menu, nav) => {
  console.log(menu);
  console.log(nav);
  if (nav.children?.length && nav.children[0].url) {
    const headMenus = menu.children.map((item) => ({
      label: item.label,
      url: item.children && item.children?.length ? item.children[0].url : "",
      children: item.children,
    }));
    const sideMenus = nav.children;
    menuStore.headerMenus = headMenus;
    menuStore.sideMenus = sideMenus;
    router.push(nav.children[0].url);
  }
};

const handleClickMenu = (menu) => {
  console.log(menu);
};
</script>

<template>
  <div class="home-view">
    <page-header :menu-visible="false" style="justify-content: space-between" />
    <right-side-bar />
    <div class="navs">
      <div
        class="menu"
        v-for="(menu, index) in menus"
        :key="index"
        :style="{
          background: `url(${menu.icon}) no-repeat top left`,
          'background-color': '#fff',
        }"
        @click="handleClickMenu(menu)"
      >
        <div class="menu-label">{{ menu.label }}</div>
        <div class="box">
          <div
            class="nav"
            v-for="(nav, idx) in menu.children"
            :key="`${index}-${idx}`"
            @click="toUrl(menu, nav)"
            :class="{
              disabled: !nav.url,
            }"
          >
            <img :src="nav.icon" />
            <div class="nav-text">{{ nav.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home-view {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/home/background.png") no-repeat right
    bottom;
  background-color: #f8f8fc;
}
.navs {
  width: 1630px;
  padding-top: 64px;
  display: flex;
  flex-wrap: wrap;
  gap: 41px 35px;
  margin: 0 auto;
  .menu {
    padding-top: 31px;
    box-sizing: border-box;
    border-radius: 8px;
    width: 520px;
    height: 400px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 1px 4px rgba(15, 26, 46, 0.15);
    position: relative;
    &-label {
      color: #fff;
      text-align: center;
      font-family: Merck;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .box {
      height: 180px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 16px;
      .nav {
        text-align: center;
        max-width: 80px;
      }
      .nav.disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
      .nav-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        margin-top: 20px;
        color: #1b1b25;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
.right-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 50px;
}
</style>
